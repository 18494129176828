<template>
  <div>
    <div class="row row-xs">
      <div :class="'col-md-12 col-lg-' + mainContentColWidth">
        <!-- Local Policy Details -->
        <div class="row">
          <div class="col">
            <card>
              <!--         Top row in card   -->
              <div class="row justify-content-between">
                <!--           Programme title and ID   -->
                <div class="col-6 pb-3">
                  <h3 class="mb-0 card-title">
                    Local Policy
                  </h3>
                  <b-overlay :show="!loadedLocalPolicy" rounded="lg" spinner-small>
                    <span class="text-muted">
                      <em>
                        On programme
                        <router-link :to="{
                      name: 'Programme Overview',
                      params: {programmeId: this.programmeId}
                    }">
                          {{ programme.name }}
                        </router-link>
                    </em></span>
                  </b-overlay>
                </div>
                <!--           Programme selection dropdown   -->
                <div class="col-4">
                  <div class="form-group">
                    <select class="form-control" @input="$router.push({name: 'Local Policy Overview', params: {localPolicyId: $event.target.value}})">
                      <option
                          v-for="row in siblingLocalPolicies"
                          :value="row.local_policy_id"
                          :key="row.local_policy_id"
                          :selected="localPolicyId==row.local_policy_id"
                      >
                        {{ row.country.country_name + ' -  Policy ID: ' + row.local_policy_id }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!--         Detail view/edit row   -->
              <div class="row">
                <div class="col">
                  <card v-if="loadedLocalPolicy" style="background-color: rgba(165,165,255,0.15);" :shadow="false">
                    <!-- MPol-level information -->
                    <div class="row row-xs mt-2">
                      <data-row>
                        <template slot="name">Local Policy Name</template>
                        <template slot="value">{{localPolicyName}}</template>
                      </data-row>
                      <data-row>
                        <template slot="name">Client</template>
                        <template slot="value">{{$store.getters.client?.client_name ?? programme?.client_name}}</template>
                      </data-row>
                    </div>
                    <div class="row row-xs my-2">
                      <data-row>
                        <template slot="name">Class</template>
                        <template slot="value">{{programme.class}}</template>
                      </data-row>
                      <data-row>
                        <template slot="name">Local Policies</template>
                        <template slot="value">{{programme.lpolCount}}</template>
                      </data-row>
                    </div>
                    <div class="row row-xs my-2">
                      <data-row>
                        <template slot="name">Currency</template>
                        <template slot="value">{{ localPolicyCurrency }}</template>
                      </data-row>
                      <data-row>
                        <template slot="name">Total Premium</template>
                        <template slot="value">{{ formatThousandsMixin(totalPremium) }} {{ localPolicyCurrency }}</template>
                      </data-row>
                    </div>
                    <div class="row row-xs my-2">
                      <data-row>
                        <template slot="name">Status</template>
                        <template slot="value"><status-badge :status-mask="localPolicy.status" /></template>
                      </data-row>
                      <data-row v-if="localPolicy.flag_freedom_of_service==1 && fosCountries.length > 0">
                        <template slot="name">FOS Countries</template>
                        <template slot="value">{{ fosCountries }}</template>
                      </data-row>
                      <data-row v-if="localPolicy.flag_non_admitted==1 && naCountries.length > 0">
                        <template slot="name">Non-Admitted Countries</template>
                        <template slot="value">{{ naCountries }}</template>
                      </data-row>
                    </div>
                  </card>
                </div>
              </div>
            </card>
          </div>
        </div>
        <div class="row mg-t-10 hide">
          <div class="col">
            <card>
              <h5>Policy Handler Contacts</h5>
              <policy-contacts :contacts="policyHandlers" />
            </card>
          </div>
        </div>
        <!-- Policy Status List - Shown on md and smaller screens, hidden otherwise -->
        <div class="row d-lg-none d-md-flex">
          <div class="col">
            <policy-status-list v-if="loadedLocalPolicy"
                                :collapsed-stats-col-width="collapsedStatsColWidth"
                                :local-policy="localPolicy" />
          </div>
        </div>
        <!-- Coverage -->
        <div class="row mg-t-10">
          <div class="col">
            <card>
              <h5>Coverage</h5>
              <b-overlay v-if="!loadedLocalPolicy" spinner-small rounded="lg">
                Getting Local Policy
              </b-overlay>
              <!--      TODO: finish the coverage document component    -->
              <coverage-document v-if="loadedLocalPolicy"
                                 :local-policy-id="localPolicy.local_policy_id"
                                 :programme-id="localPolicy.master_policy_id">
              </coverage-document>
            </card>
          </div>
        </div>
        <!-- Finance -->
        <div class="row mg-t-10">
          <div class="col">
            <card>
              <h5>Finance</h5>
              <b-overlay v-if="!loadedLocalPolicy" spinner-small rounded="lg">
                Getting Local Policy
              </b-overlay>
              <!--      TODO: Create a finance table component and populate with lines attached to localPolicy!    -->
              <!--      FINANCE TABLE COMPONENT HERE -->
              <table v-if="loadedLocalPolicy" class="table table-striped table-hover">
                <thead>
                <tr>
                  <th>Transaction Reference</th>
                  <th>Country</th>
                  <th>CCY</th>
                  <th>Premium</th>
                  <th>Total Collectible</th>
                  <th>Collection Type</th>
                  <th>Paid</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(line, index) in localPolicy.lines" :key="index">
                  <td>{{ line.transaction_reference }}</td>
                  <td>{{ getCountryForLine(line) }}</td>
                  <td>{{ localPolicyCurrency }}</td>
                  <td>{{ formatThousandsMixin(line.dec_risk_premium) }}</td>
                  <td>{{ formatThousandsMixin(line.dec_total_collectable) }}</td>
                  <td>{{ lineCollectionType(line) }}</td>
                  <td>
                    <b-badge v-if="line.flag_payment_made==1" variant="success">Yes</b-badge>
                    <b-badge v-else variant="warning">No</b-badge>
                  </td>
                </tr>
                </tbody>
              </table>
            </card>
          </div>
        </div>
        <!-- Key Contacts -->
        <div class="row mg-t-10">
          <div class="col">
            <card>
              <h5>Key Contacts</h5>
              <key-contacts-read-only v-if="loadedLocalPolicy"
                                      :local-policies="[localPolicy]"
              ></key-contacts-read-only>
            </card>
          </div>
        </div>
        <!-- Locations row   -->
        <div class="row mg-t-10" v-if="programme.class==='Property'">
          <div class="col">
            <card>
              <div class="row">
                <div class="col">
                  <h5>Locations</h5>
                  <span v-if="locations.length===0">
                    There are no locations attached to this local policy
                  </span>
                  <table v-else class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th>Location</th>
                      <th>Local Client</th>
                      <th>Latitude / Longitude</th>
                      <th>Total Location Insured</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(location, index) in locations" :key="index">
                      <td>
                        <router-link :to="{
                          name: 'Location',
                          params: {
                            programmeId,
                            localPolicyId,
                            locationId: location.location_transaction_detail_id,
                          }
                        }"
                                     v-b-tooltip:hover title="View Location"
                        >
                          {{ location.address1_firstline}}
                        </router-link>
                      </td>
                      <td>{{ location.local_client_name}}</td>
                      <td>{{ location.geo_latitude + ' / ' + location.geo_longitude }}</td>
                      <td>{{ totalLocationSumInsured(location) }}</td>
                      <td>
                        <router-link :to="{
                          name: 'Location',
                          params: {
                            programmeId,
                            localPolicyId,
                            locationId: location.location_transaction_detail_id
                          }
                        }">
                          <globe-icon size="1x" />&nbsp;View Location
                        </router-link>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </card>
          </div>
        </div>
        <div v-if="usingClaimsFeatures()" class="row mg-t-10">
          <div class="col">
            <card>
              <h5>Claims</h5>
              <claim-table v-if="loadedLocalPolicy && claims.length > 0" :claims="claims" />
              <span v-else-if="!loadedLocalPolicy">Getting Local Policy</span>
              <span v-else>No claims on this Local Policy</span>
            </card>
          </div>
        </div>
      </div>
      <!-- Policy Status List - Shown on lg and larger screens, hidden otherwise -->
      <div :class="'d-md-none d-lg-block col-lg-' + sidebarColWidth">
        <policy-status-list v-if="loadedLocalPolicy"
                            :collapsed-stats-col-width="collapsedStatsColWidth"
                            :local-policy="localPolicy" />
      </div>
    </div>
  </div>
</template>
<script>
import KeyContactsReadOnly from "@common/src/components/KeyContactsReadOnly";
// import LocationViewReadOnly from "@/components/LocationViewReadOnly";
import DataRow from "@common/src/components/DataRow";
import { GlobeIcon } from "vue-feather-icons";
import CoverageDocument from "@common/src/components/CoverageDocument";
import PolicyStatusList from "@common/src/components/PolicyStatusList";
import PolicyMixin from "@clients/src/mixins/policy.mixin";
import ClaimTable from "@common/src/components/ClaimTable";
import PolicyContacts from "@common/src/components/PolicyContacts";
import StatusBadge from "@common/src/components/StatusBadge";
import FeaturesMixin from '@clients/src/mixins/features.mixin';

export default {
  name: 'LocalPolicyOverview',
  components: {
    ClaimTable,
    PolicyStatusList,
    CoverageDocument,
    // LocationViewReadOnly,
    KeyContactsReadOnly,
    DataRow,
    GlobeIcon,
    PolicyContacts,
    StatusBadge
  },
  mixins: [PolicyMixin, FeaturesMixin],
  props: {
    localPolicyId: {
      type: [Number, String],
      default: 0
    },
    programmeId: {
      type: [Number, String],
      default: 0
    },
  },
  data: () => ({
    sidebarColWidth: 3,
    collapsedStatsColWidth: 3,
  }),
  computed: {
    localPolicy: function() {
      return this.$store.getters.localPoliciesForProgramme.find(lPol => lPol.local_policy_id == this.localPolicyId);
    },
    claims: function() {
      return this.loadedLocalPolicy ? this.$store.getters.claims.filter(claim => claim.local_policy_id==this.localPolicyId) : [];
    },
    loadedLocalPolicy: function() {
      return this.localPolicy !== undefined && this.localPolicy.local_policy_id==this.localPolicyId;
    },
    localPolicyName() {
      return this.loadedLocalPolicy ? this.localPolicy.local_policy_name : 'Loading';
    },
    localPolicyCurrency() {
      return this.loadedLocalPolicy ? this.programme.currencyCode : 'Loading';
    },
    siblingLocalPolicies: function() {
      return this.$store.getters.localPoliciesForProgramme.filter(lPol =>
          (lPol.master_policy_id==this.programmeId)
      );
    },
    programme: function() {
      const prog  = this.$store.getters.programmes.find(prog =>
          (prog.id==this.programmeId)
      );
      return prog ?? {};
    },
    lines: function() {
      return this.localPolicy.lines ?? [];
    },
    locations: function() {
      return this.programme.class==='Property' && this.loadedLocalPolicy ? this.localPolicy?.locations : [];
    },
    totalPremium: function() {
      return this.calculateTotalPremium(this.localPolicy.lines);
    },
    mainContentColWidth: function() {
      return 12 - this.sidebarColWidth;
    },
    fosCountries: function() {
      if (!this.loadedLocalPolicy || !this.localPolicy.countries || this.localPolicy.countries.length === 0) {
        return [];
      }
      let sFOSCountries = '';
      const countryCount = this.localPolicy.countries.length;
      for (let i = 0; i < countryCount - 1; i++) {
        sFOSCountries += this.localPolicy.countries[i].country_name;
        if (countryCount > 2) {
          sFOSCountries += ', ';
        } else if (countryCount === 2) {
          sFOSCountries += ' ';
        }
      }
      sFOSCountries += 'and ' + this.localPolicy.countries[countryCount - 1].country_name;
      return sFOSCountries;
    },
    naCountries: function() {
      if (!this.loadedLocalPolicy || !this.localPolicy.countries || this.localPolicy.countries.length === 0) {
        return [];
      }
      let sNACountries = '';
      const countryCount = this.localPolicy.countries.length;
      for (let i = 0; i < countryCount - 1; i++) {
        sNACountries += this.localPolicy.countries[i].country_name;
        if (countryCount > 2) {
          sNACountries += ', ';
        } else if (countryCount === 2) {
          sNACountries += ' ';
        }
      }
      sNACountries += 'and ' + this.localPolicy.countries[countryCount - 1].country_name;
      return sNACountries;
    },
    policyHandlers: function() {
      let handlers = [];
      if (this.localPolicy === undefined) {
        return handlers;
      }
      if (this.localPolicy.primary_handler_company_contact) {
        let handler = this.localPolicy.primary_handler_company_contact;
        handler.type = "Primary Handler";
        handler.image = this.localPolicy.primary_handler_company_contact.image_name;
        handlers.push(handler);
      }
      if (this.localPolicy.secondary_handler_company_contact) {
        let handler = this.localPolicy.secondary_handler_company_contact;
        handler.type = "Secondary Handler";
        handler.image = this.localPolicy.secondary_handler_company_contact.image_name;
        handlers.push(handler);
      }
      if (this.localPolicy.primary_manager_company_contact) {
        let handler = this.localPolicy.primary_manager_company_contact;
        handler.type = "Primary Manager";
        handler.image = this.localPolicy.primary_manager_company_contact.image_name;
        handlers.push(handler);
      }
      if (this.localPolicy.secondary_manager_company_contact) {
        let handler = this.localPolicy.secondary_manager_company_contact;
        handler.type = "Secondary Manager";
        handler.image = this.localPolicy.secondary_manager_company_contact.image_name;
        handlers.push(handler);
      }

      return handlers;
    }
  },
  methods: {
    totalLocationSumInsured(location) {
      let currencyCode = location.currency?.currency_code;
      currencyCode = currencyCode ? currencyCode + ' ' : '';
      return currencyCode + this.formatThousandsMixin(location.total_location_sum_insured);
    },
    getCountryForLine(line) {
      return line?.fos_non_adm_country?.country_name ?? line?.country?.country_name ?? '';
    }
  },
  watch: {
    localPolicyId: async function(newId) {
      // This id watch function will be fired if the user changes the ID in the
      //  URL, clicks a link to the same view, or does an action that just reuses
      //  this component. When that happens, we need to check if a programme with
      //  the given ID exists, redirect if not.
      // For the above cases the beforeRouteEnter listener below is not fired.
      const localPoliciesLoaded = await this.$store.dispatch(
          'getLocalPolicies',
          {master_policy_id: this.programmeId}
      );
      const localPolicy = this.$store.getters.localPoliciesForProgramme.find(lPol => lPol.local_policy_id == newId);
      if (!localPoliciesLoaded || !localPolicy) {
        this.$root.$bvToast.toast(
            'That local policy is not available',
            {title: 'Local Policy Error', variant: 'danger', autoHideDelay:5000}
        );
        this.$router.replace({name: 'Dashboard'});
      } else {
        this.$store.dispatch('loadClaimsForLocalPolicy', { local_policy_id: newId });
      }
    }
  },
  // This listener is fired when the browser first arrives on this view from a
  //  a different route... It is also fired if a link is typed/pasted in to the
  //  overview for another programme ID.
  // The above watch id function is not fired before route enter.
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      const localPoliciesLoaded = await vm.$store.dispatch(
          'getLocalPolicies',
          {master_policy_id: to.params.programmeId}
      );
      const localPolicy = vm.$store.getters.localPoliciesForProgramme.find(lPol => lPol.local_policy_id == to.params.localPolicyId);
      if (!localPoliciesLoaded) {
        vm.$root.$bvToast.toast(
            'An unexpected error occurred while retrieving that local policy, please try again later',
            {title: 'Local Policy Error', variant: 'danger', autoHideDelay:5000}
        );
        next({name: 'Dashboard'});
      } else if (!localPolicy) {
        vm.$root.$bvToast.toast(
            'That local policy is not available',
            {title: 'Local Policy Error', variant: 'danger', autoHideDelay:5000}
        );
        next({name: 'Dashboard'});
      } else {
        vm.$store.dispatch('loadClaimsForLocalPolicy', { local_policy_id: to.params.localPolicyId })
        next();
      }
    });
  }
};
</script>
