<template>
	<div class="row">

		<div class="col-12">
			<div class="row">
				<div class="col-12">
					<div class="card border-0" v-if="companyData">
						<div class="card-header p-0 d-flex flex-row">
							<img class="d-flex" v-if="getImageSrc('country-flags/' + companyData.country.img_flag_path)" :src="getImageSrc('country-flags/' + companyData.country.img_flag_path)" :alt="companyData.country.country_name" :width="90"/>
							<h4 class="d-flex p-3 m-0">{{ companyData.issuingOffice.regional_company_name }}</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
						<b-tabs>
							<b-tab active>
								<template #title>
									<strong>Office Wiki</strong>
								</template>
								<b-card>
									<b-overlay v-if="!officeData && loadingCompanyData" spinner-small rounded="lg">
										Loading Office Wiki...
									</b-overlay>
									<b-overlay v-if="!officeData && !loadingCompanyData" spinner-small rounded="lg">
										Could not retrieve Office Wiki, please try again later.
									</b-overlay>
									<div class="row" v-if="officeData">
										<div class="col-6">
											<table class="table table-bordered table-striped">
												<tbody>
												<tr>
													<td class="font-weight-bold">Office Address</td>
													<td>
														<span class="d-block" v-if="officeData.wiki_address_1">{{ officeData.wiki_address_1 }}</span>
														<span class="d-block" v-if="officeData.wiki_address_2">{{ officeData.wiki_address_2 }}</span>
														<span class="d-block" v-if="officeData.wiki_address_3">{{ officeData.wiki_address_3 }}</span>
														<span class="d-block" v-if="officeData.wiki_address_city">{{ officeData.wiki_address_city }}</span>
														<span class="d-block" v-if="officeData.wiki_postcode">{{ officeData.wiki_postcode }}</span>
													</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Office Website</td>
													<td>{{ officeData.wiki_web_address }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Credit Rating</td>
													<td>{{ officeData.wiki_credit_rating }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Office Hours</td>
													<td>{{ officeData.wiki_office_hours }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Office Notes</td>
													<td>{{ officeData.wiki_notes }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Run-off</td>
													<td v-if="officeData.flag_in_run_off === 1">
															<b-badge variant="warning"><i class="fa fa-lock"></i>&nbsp;Office is in Run-off</b-badge>
													</td>
													<td v-else-if="officeData.flag_in_run_off === 0">
														<b-badge variant="success"><i class="fa fa-lock-open"></i>&nbsp;Active</b-badge>
													</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Company Registration Number</td>
													<td>{{ officeData.wiki_company_registration_number }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Tax Registration ID</td>
													<td>{{ officeData.wiki_tax_registration_id }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Duns No</td>
													<td>{{ officeData.wiki_duns_no }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Approved Network Partner</td>
													<td v-if="officeData.wiki_approved_network_partner === 1">Yes</td>
													<td v-else-if="officeData.wiki_approved_network_partner === 0">No</td>
													<td v-else>-</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Guidewire Agency Number</td>
													<td>{{ officeData.wiki_guidewire_agency_number }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Reinsurance Agreement In Place</td>
													<td v-if="officeData.wiki_reinsurance_agreement_in_place === 1">Yes</td>
													<td v-else-if="officeData.wiki_reinsurance_agreement_in_place === 0">No</td>
													<td v-else>-</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Any key exceptions to standard Aviva Reinsurance Agreement terms</td>
													<td>{{ officeData.wiki_key_exceptions_to_standard_terms }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">FAC Slip Format</td>
													<td>{{ officeData.wiki_fac_slip_format }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Can you incorporate Aviva's standard cross class exclusions?</td>
													<td>{{ officeData.wiki_standard_cross_class_exclusions }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Local Risk Engineering Available?</td>
													<td>{{ officeData.wiki_local_risk_engineering }}</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Audited Financials Available?</td>
													<td v-if="officeData.wiki_audited_financials === 1">Yes</td>
													<td v-else-if="officeData.wiki_audited_financials === 0">No</td>
													<td v-else>-</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Fronting Services To Other Insurers</td>
													<td v-if="officeData.wiki_fronting_services_to_other_insurers === 1">Yes</td>
													<td v-else-if="officeData.wiki_fronting_services_to_other_insurers === 0">No</td>
													<td v-else>-</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Dedicated Reverse Flow Team</td>
													<td v-if="officeData.wiki_dedicated_reverse_flow_team === 1">Yes</td>
													<td v-else-if="officeData.wiki_dedicated_reverse_flow_team === 0">No</td>
													<td v-else>-</td>
												</tr>
												<tr>
													<td class="font-weight-bold">Issuing Office Notes/Alerts</td>
													<td>{{ officeData.wiki_io_notes }}</td>
												</tr>
												</tbody>
											</table>
										</div>
										<div class="col-6">
											<div class="row">
												<div class="col-12">
													<div class="card border border-dark p-2" v-if="officeData.wiki_underwriter_notes">
														<h5 class="card-header">
															Underwriter Notes
														</h5>
														<div class="card-body" v-html="formatMcoeNote(officeData.wiki_underwriter_notes)"></div>
													</div>
												</div>
											</div>
											<div class="row" v-if="companySettings">
												<div class="col-12">
													<div class="card">
														<h5 class="card-header">
															Placement Options
														</h5>
														<div class="card-body">
															<table class="table table-bordered table-striped">
																<tbody>
																	<tr>
																		<td class="font-weight-bold w-50">Is Non-Admitted Coverage permissible?</td>
																		<td>
																			{{ companySettings.flag_non_admitted === 1 ? 'Yes' : 'No' }}
																			<span class="d-block font-italic pt-2" v-if="companySettings.non_admitted_comment">
																				{{companySettings.non_admitted_comment}}
																			</span>
																		</td>
																	</tr>
																	<tr v-if="companySettings.AxcoNonAdm">
																		<td colspan="2">
																			<b-button v-b-toggle="'collapse_' + 9000" class="btn-sm btn-light d-flex flex-row">
																				<img class="d-flex mr-2" src="@/assets/img/default/AxcoLogoSmall.png" alt="Axco Logo" :width="20"/>
																				Axco answer for whether Non Admitted Policies are possible
																			</b-button>
																			<b-collapse :id="'collapse_' + 9000">
																				<div class="card card-body border-info border-1 mt-2 axco-feature">
																					<table class="table">
																						<tbody>
																							<tr v-for="axcoData in companySettings.AxcoNonAdm">
																								<td>{{ axcoData.policy_class_type.policy_class_type_caption }}</td>
																								<td v-html="axcoData.answer"></td>
																							</tr>
																						</tbody>
																					</table>
                                          <hr>
                                          <span class="axco-compliance-message">
                                            Compliance data provided under licence by Axco.
                                            Copyright 2012-{{ currentYear }}, Axco Insurance Information Services Ltd.
                                          </span>
																				</div>
																			</b-collapse>
																		</td>
																	</tr>
																	<tr>
																		<td class="font-weight-bold w-50">Is Freedom of Service coverage available?</td>
																		<td>
																			{{ companySettings.flag_freedom_of_service === 1 ? 'Yes' : 'No' }}
																			<span class="d-block font-italic pt-2" v-if="companySettings.freedom_of_service_comment">
																				{{companySettings.freedom_of_service_comment
																				}}
																			</span>
																		</td>
																	</tr>
																	<tr v-if="companySettings.AxcoFOS">
																		<td colspan="2">
																			<b-button v-b-toggle="'collapse_' + 9001" class="btn-sm btn-light d-flex flex-row">
																				<img class="d-flex mr-2" src="@/assets/img/default/AxcoLogoSmall.png" alt="Axco Logo" :width="20"/>
																				Axco answer for whether FOS policies are possible
																			</b-button>
																			<b-collapse :id="'collapse_' + 9001">
																				<div class="card card-body border-info border-1 mt-2 axco-feature">
																					<table class="table">
																						<tbody>
																						<tr v-for="axcoData in companySettings.AxcoFOS">
																							<td>{{ axcoData.policy_class_type.policy_class_type_caption }}</td>
																							<td v-html="axcoData.answer"></td>
																						</tr>
																						</tbody>
																					</table>
                                          <hr>
                                          <span class="axco-compliance-message">
                                            Compliance data provided under licence by Axco.
                                            Copyright 2012-{{ currentYear }}, Axco Insurance Information Services Ltd.
                                          </span>
																				</div>
																			</b-collapse>
																		</td>
																	</tr>
																	<tr>
																		<td class="font-weight-bold w-50">Is Financial Interest Clause (FINC) coverage available?</td>
																		<td>
																			<span class="d-block">{{ companySettings.flag_financial_interest_clause === 1 ? 'Yes' : 'No' }}</span>
																			<span class="d-block font-italic pt-2" v-if="companySettings.financial_interest_clause_comment">
																				{{companySettings.financial_interest_clause_comment}}
																			</span>
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
													<div class="card" v-if="frontingCommission">
														<h5 class="card-header">
															Fronting Commission
														</h5>
														<div class="card-body">
															<table class="table table-bordered table-striped">
																<tbody>
																	<tr v-for="feature in frontingCommission">
																		<td class="font-weight-bold w-50">{{feature.feature_name}}</td>
																		<td>
																			{{ feature.feature_value }}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</b-card>
							</b-tab>
							<b-tab title="Capabilities & Taxation">
								<b-card>
									<div class="row">
										<div class="col-9">
											<b-card>
												<b-overlay v-if="!wikiData && loadingWiki" spinner-small rounded="lg">
													Loading Country Data...
												</b-overlay>
												<b-overlay v-if="!wikiData && !loadingWiki" spinner-small rounded="lg">
													Could not retrieve Country Data, please try again later.
												</b-overlay>
												<h4 v-if="selectedClass && wikiData" class="mt-0">{{ selectedClass }}</h4>
												<div v-if="wikiData" v-for="(sectionData, section) in wikiData" :key="section">
													<h5 class="mt-4"><strong>{{section}}</strong></h5>
													<table class="table  table-bordered">
														<tbody>
															<template v-for="(feature, index) in sectionData.features">
																<template v-if="feature.flag_subheading === 1">
																	<tr>
																		<td :colspan="3" class="bg-gray-1"><strong>{{ feature.feature_subheading }}</strong></td>
																	</tr>
																	<tr>
																			<td class="w-50">{{ feature.feature_name }} <span v-if="feature.currency_code">({{ feature.currency_code }})</span></td>
																			<td class="w-25">{{ feature.feature_value }}</td>
																			<td class="w-25">{{ feature.feature_additional_value }}</td>
																	</tr>
																</template>
																<template v-else>
																	<tr>
																		<td class="w-50">{{ feature.feature_name }} <span v-if="feature.currency_code">({{ feature.currency_code }})</span></td>
																		<td class="w-25">{{ feature.feature_value }}</td>
																		<td class="w-25">{{ feature.feature_additional_value }}</td>
																	</tr>
																</template>
																<!-- Check if feature has axco_answers -->
																<template v-if="feature.axco_answers">
																	<!-- Add a row with colspan 3 underneath -->
																	<tr>
																		<td :colspan="3">
																			<b-button v-b-toggle="'collapse_' + index" class="btn-sm btn-light d-flex flex-row">
																				<img class="d-flex mr-2" src="@/assets/img/default/AxcoLogoSmall.png" alt="Axco Logo" :width="20"/>
																				Axco answer for {{ feature.axco_feature_name }}
																			</b-button>
																			<b-collapse visible :id="'collapse_' + index">
																				<div class="card card-body border-info border-1 mt-2 axco-feature">
																					<span v-html="feature.axco_answers.answer"></span>
                                          <hr>
                                          <span class="axco-compliance-message">
                                            Compliance data provided under licence by Axco.
                                            Copyright 2012-{{ currentYear }}, Axco Insurance Information Services Ltd.
                                          </span>
																				</div>
																			</b-collapse>
																		</td>
																	</tr>
																</template>
															</template>
														</tbody>
													</table>
												</div>
											</b-card>
										</div>
										<div class="col-3">
											<b-card no-body class="border-0">
												<ul class="list-group list-group-root">
													<li class="list-group-item top-level-group" v-for="(item, index) in classes" :key="index">
														<span class="list-group-header" :class="{ active: selectedItem === item.policy_class_type_id }"  @click="selectItem(item.policy_class_type_id, item.policy_class_type_caption)">{{ item.policy_class_type_caption }}</span>
														<ul class="list-group">
															<li class="list-group-item nested-group" v-for="(policyClass, childIndex) in item.policy_classes" :key="childIndex">
																<span class="list-subgroup-header"  :class="{ active: selectedItem === policyClass.policy_class_id }" @click="selectItem(item.policy_class_type_id, (item.policy_class_type_caption + ' - ' + policyClass.policy_class_caption), policyClass.policy_class_id)">{{ policyClass.policy_class_caption }}</span>
															</li>
														</ul>
													</li>
												</ul>
											</b-card>
										</div>
									</div>
								</b-card>
							</b-tab>
						</b-tabs>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ProjectsTable from "@common/src/views/Tables/ProjectsTable";
import FeaturesMixin from "@/mixins/features.mixin";
import LoginModal from "@underwriters/src/components/LoginModal";
import FormatterMixin from "@underwriters/src/mixins/formatter.mixin";

import axios from "axios";
import ApiService from "@clients/src/services/api.service";
import CompanyWikiFeatureTable from "@clients/src/components/CompanyWikiFeatureTable.vue";

export default {
	name: "WikiCountryData",
	mixins: [FeaturesMixin, FormatterMixin],
	props: {
		companyId: {
			type: [Number, String],
		},
	},
	components: {CompanyWikiFeatureTable, ProjectsTable, LoginModal },
	data: () => {
		return {
			officeWiki: null,
			wikiData: null,
			classes: null,
			selectedItem: null,
			selectedClass: null,
			loadingWiki: false,
			loadingCompanyData: false,
			companyData: null,
			officeData: null,
			companySettings: null,
			frontingCommission: null,
      currentYear: new Date().getFullYear(),
		};
	},
	methods: {
		toggleCollapse(index) {
			this.classes.forEach((classItem, i) => {
				if (i !== index) {
					this.$set(classItem, 'open', false);
				}
			});
			this.$set(this.classes[index], 'open', !this.classes[index].open);
		},
		getImageSrc(imgPath) {
			try {
				return require(`@/assets/img/default/${imgPath}`);
			} catch (error) {
				return null;
			}
		},
		formatMcoeNote: function(value) {
			if (value) {
				return value.replace(/ò/g, (match, index) => {
					if (index === 0) {
						return "&#8226";
					} else {
						return "<br>&#8226";
					}
				});
			}
			return "";
		},
		selectItem(policyTypeId, caption, policyClassId = null) {

			if(policyClassId) {
				this.selectedItem = policyClassId;
			} else {
				this.selectedItem = policyTypeId;
			}

			this.selectedClass = caption;

			this.getWikiData(policyTypeId, policyClassId);
		},
		async getOfficeData() {

			this.loadingCompanyData = true;

			let { data } = await ApiService.get(
				process.env.VUE_APP_API_ENDPOINT + "/company/" + this.companyId,
				{
					withCredentials: true
				}
			);

			if(data.success) {
				this.officeData = data.company;
				this.companySettings = data.companySettings;
				this.frontingCommission = data.frontingCommission;
			}

			this.loadingWiki = false;
		},
		async findOfficeBasicData() {
			const companyData =  this.$store.getters.getAllCountries.flatMap(country =>
				country.issuingOffices.filter(office => office.company_id == this.companyId)
					.map(office => ({ country: country, issuingOffice: office }))
			);

			this.companyData = companyData[0];
		},
		async getWikiData(policyTypeId, policyClassId = null) {

			this.loadingWiki = true;

			this.wikiData = null;

			let url = process.env.VUE_APP_API_ENDPOINT + "/wiki/all/" + this.companyId + "/" + policyTypeId;

			if (policyClassId !== null) {
				url += "/" + policyClassId;
			}

			let { data } = await ApiService.get(
				url,
				{
					withCredentials: true
				}
			);

			if(data.success === true) {
				this.wikiData = data.wikiData;
			}

			this.loadingWiki = false;
		},
		async loadClassesAndProducts()
		{
			await this.$store.dispatch("loadClassesAndProducts");

			this.classes = this.$store.getters.classesAndProducts;
		}
	},
	mounted() {
		this.findOfficeBasicData();
		this.getOfficeData();
		this.loadClassesAndProducts();
		this.selectItem(1, 'Financial Lines');
	}
};
</script>
<style>
.list-group.list-group-root {
	padding: 0;
	overflow: hidden;
}

.list-group.list-group-root .list-group {
	margin-bottom: 0;
}
.list-group:first-child .list-group-item:first-child {
	border-radius: 0 !important;
}
.top-level-group {
	padding: 0;
}
.list-group.list-group-root .list-group-item {
	border-radius: 0;
	border-width: 1px 0 0 0;
	padding: 0;
	cursor: pointer;
}

.list-group-item > span:hover {
	background-color: #191D64;
	color: #FFFFFF;
}

.list-group-item > span.active {
	background-color: #191D64;
	color: #FFFFFF;
}

.list-group-header {
	font-weight: bold;
	padding: 10px 0 10px 15px;
	display: block;
}

.list-subgroup-header {
	padding: 10px 15px 10px 30px;
	display: block;
}

.axco-feature p {
	font-size: 0.875rem;
}

.axco-compliance-message {
  font-size: x-small;
  padding-top: 5px;
}
</style>