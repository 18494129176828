<template>
  <b-modal v-model="modalShowModel" title="Bind Policy" hide-footer>
    <b-form @submit.prevent="submitForm">

      <p v-show="!markNotBound">Send request to PO Handler to bind the policy.</p>
      <p v-show="markNotBound">Send request to PO Handler to mark this policy as not bound.</p>

      <hr />
      <br />

      <b-button variant="secondary" @click="hideModal()">Cancel</b-button>

      <b-button v-show="!markNotBound" type="submit" variant="primary" class="float-right">
        <i class="fa fa-link"></i>&nbsp;
        Request Bind
      </b-button>
      <b-button v-show="markNotBound" type="submit" variant="danger" class="float-right">
        <i class="fa fa-window-close"></i>&nbsp;
        Mark Not Bound
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import UnderwritingService from "@underwriters/src/services/underwriting.service";

export default {
  name: "BindPolicyModal",
  props: {
    inquiryId: {
      type: [Number, String]
    },
    modalShow: {
      type: Boolean,
      default: false
    },
    markNotBound: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      additionalInfo: "",
      selectedFile: null,
      acceptedFileTypes: process.env.VUE_APP_ACCEPTED_FILE_TYPES
    };
  },
  methods: {
    async submitForm() {
      let responseData = await UnderwritingService.requestBindPolicy(
        this.inquiryId,
        this.markNotBound
      );

      if (responseData) {
        this.$emit("policyBindRequestSentSuccessfully", {
          inquiryId: this.inquiryId,
          bindRequest: responseData.bindRequest
        });
      }

      this.hideModal();
    },
    hideModal() {
      this.$emit("hideModal");
    }
  },
  computed: {
    modalShowModel: {
      get() { return this.modalShow },
      set(value) { this.$emit('update:modalShow', value) },
    }
  }
};
</script>
