import ApiService from "@/services/api.service";
import Vue from "vue";
import { SET_BIND_REQUEST } from "@underwriters/src/store/mutation-types";
import store from "@/store";

const UnderwritingService = {
  async sendAdditionalInfoToPOHandler(inquiryId, additionalInfo, file) {
    let formData = new FormData();
    formData.append("inquiryId", inquiryId);
    formData.append("file", file);
    formData.append("additionalInfo", additionalInfo);

    try {
      let { data } = await ApiService.post(
        "/progress/additionalInfo",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      return data;
    } catch (err) {
      console.error("occurred while uploading document:", err);
      return false;
    }
  },
  async requestBindPolicy(inquiryId, bMarkNotBound) {
    let formData = new FormData();
    formData.append("inquiryId", inquiryId);
    formData.append("markNotBound", bMarkNotBound ? "1" : "0");

    try {
      let { data } = await ApiService.post(
        "/progress/requestBindPolicy",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (data.success) {
        await store.dispatch(SET_BIND_REQUEST, {
          inquiryId: inquiryId,
          bindRequest: data.bindRequest
        });

        if (bMarkNotBound) {
          Vue.toasted.success("Successfully marked the policy as not bound.");
        } else {
          Vue.toasted.success("Successfully requested to bind policy");
        }
      }

      return data;
    } catch (err) {
      console.error("occurred while sending request to bind policy:", err);
      return false;
    }
  }
};

export default UnderwritingService;
