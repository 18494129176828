<template>
  <div class="policy-donuts row donut-row">
    <div class="col col-md-4 text-center">
      <h5>Local Instructions</h5>
      <h6 class="text-muted">{{ timelinessTotal }}</h6>
      <doughnut-chart
          v-if="!timelinessEmpty"
          :height="150"
          :width="150"
          ref="donutChart"
          satisfied-label="Sent"
          :data="timeliness"
          :extra-options="extraOptions" />
      <h3 v-else-if="loadingData" class="text-muted" style="height: 142px; padding-top: 58px">
        <font-awesome-icon :icon="faSpinner" spin />
      </h3>
      <h5 v-else class="text-muted" style="height: 142px; padding-top: 58px">No finalisation data</h5>
    </div>
    <div class="col col-md-4 text-center">
      <h5>Local Policies Issued</h5>
      <h6 class="text-muted">{{ issuanceTotal }}</h6>
      <doughnut-chart
          v-if="!issuedEmpty"
          :height="150"
          :width="150"
          ref="donutChart"
          satisfied-label="Issued"
          :data="issued"
          :extra-options="extraOptions" />
      <h3 v-else-if="loadingData" class="text-muted" style="height: 142px; padding-top: 58px">
        <font-awesome-icon :icon="faSpinner" spin />
      </h3>
      <h5 v-else class="text-muted" style="height: 142px; padding-top: 58px">No policy issuance data</h5>
    </div>
    <div class="col col-md-4 text-center">
      <h5>Local Premiums Collected</h5>
      <h6 class="text-muted">{{ premiumTotal }}</h6>
      <doughnut-chart
          v-if="!premiumEmpty"
          :height="150"
          :width="150"
          ref="donutChart"
          satisfied-label="Collected"
          :data="premium"
          :extra-options="extraOptions" />
      <h3 v-else-if="loadingData" class="text-muted" style="height: 142px; padding-top: 58px">
        <font-awesome-icon :icon="faSpinner" spin />
      </h3>
      <h5 v-else class="text-muted" style="height: 142px; padding-top: 58px">No premium receipt data</h5>
    </div>
  </div>
</template>
<script>
import * as chartConfigs from '@common/src/components/Charts/config';
import DoughnutChart from "@common/src/components/Charts/DoughnutChart";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'PolicyDonuts',
  props: {
    timeliness: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    issued: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    premium: {
      type: Array,
      default: () => [0, 0, 0, 0]
    },
    loadingData: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    faSpinner,
  }),
  components: {
    DoughnutChart,
  },
  computed: {
    timelinessEmpty() {
      return this.timeliness.every(count => count === 0);
    },
    timelinessTotal() {
      return this.timeliness[0] + '/' + this.timeliness.reduce((prev, cur) => prev + cur, 0);
    },
    issuedEmpty() {
      return this.issued.every(count => count === 0);
    },
    issuanceTotal() {
      return this.issued[0] + '/' + this.issued.reduce((prev, cur) => prev + cur, 0);
    },
    premiumEmpty() {
      return this.premium.every(count => count === 0);
    },
    premiumTotal() {
      return this.premium[0] + '/' + this.premium.reduce((prev, cur) => prev + cur, 0);
    },
    extraOptions() {
      return chartConfigs.pieChartOptions;
    },
    donutGreen() {
      return {
        'background-color': '#' + process.env.VUE_APP_DONUT_GREEN,
        color: '#f1f9f2',
        cursor: 'default',
      };
    },
    donutAmber() {
      return {
        'background-color': '#' + process.env.VUE_APP_DONUT_AMBER,
        color: '#fef9e8',
        cursor: 'default',
      };
    },
    donutRed() {
      return {
        'background-color': '#' + process.env.VUE_APP_DONUT_RED,
        color: '#fbebea',
        cursor: 'default',
      };
    },
    donutSatisfied() {
      return {
        'background-color': '#' + process.env.VUE_APP_DONUT_SATISFIED,
        color: '#eaebfb',
        cursor: 'default',
      };
    },
  },
};
</script>
<style>
.policy-donuts .badge {
  margin-top: 8px;
  margin-right: 8px;
}
</style>
