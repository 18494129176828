<template>
  <div>
    <div class="row row-xs">
      <div class="col">
        <card id="form-card">
          <div class="row row-xs">
            <div class="col pb-3">
              <h3 class="mb-0 card-title">Location</h3>
              <b-overlay :show="!loadedLocation" rounded="lg" spinner-small>
                <span class="text-muted">
                  <em>
                    On local policy
                    <router-link :to="{
                  name: 'Local Policy Overview',
                  params: {programmeId, localPolicyId}
                }">
                      {{ localPolicy.local_policy_name }}
                    </router-link>
                  </em>
                </span>
              </b-overlay>
            </div>
          </div>
          <!--         Detail view/edit row   -->
          <div class="row">
            <div class="col">
              <card v-if="loadedLocation" style="background-color: rgba(165,165,255,0.15);" :shadow="false">
                <!-- MPol-level information -->
                <div class="row row-xs mt-2">
                  <data-row large-label>
                    <template slot="name">Location Name</template>
                    <template slot="value">{{ location.client_location_id }}</template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">Local Client</template>
                    <template slot="value">{{ location.local_client_name }}</template>
                  </data-row>
                </div>
                <div class="row row-xs my-2">
                  <data-row large-label>
                    <template slot="name">Address</template>
                    <template slot="value">
                      {{ location.address1_firstline }}
                      <br v-if="location.address1_firstline.length" />
                      {{ location.address2_city }}
                      <br v-if="location.address2_city.length" />
                      {{ location.address3_state_or_county }}
                      <br v-if="location.address3_state_or_county.length && location.address4_postal_code.length" />
                      {{ location.address4_postal_code }}
                    </template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">Coordinates</template>
                    <template slot="value">{{ location.geo_latitude + ', ' + location.geo_longitude }}</template>
                  </data-row>
                </div>
                <div class="row row-xs">
                  <data-row large-label>
                    <template slot="name">Currency</template>
                    <template slot="value">{{ location.currency.currency_code }}</template>
                  </data-row>
                </div>
                <div class="row row-xs" v-if="location.EDP_sum_insured > 0">
                  <data-row large-label>
                    <template slot="name">EDP Sum Insured</template>
                    <template slot="value">{{ location.EDP_sum_insured | formatCurrency }}</template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">EDP EML</template>
                    <template slot="value">{{ location.EDP_EML * 100 }}%</template>
                  </data-row>
                </div>
                <div class="row row-xs" v-if="location.bis_interrupt_sum_insured > 0">
                  <data-row large-label>
                    <template slot="name">Business Interruption Sum Insured</template>
                    <template slot="value">{{ location.bis_interrupt_sum_insured | formatCurrency }}</template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">Business Interruption EML</template>
                    <template slot="value">{{ location.bis_interrupt_EML * 100 }}%</template>
                  </data-row>
                </div>
                <div class="row row-xs"
                     v-if="!(location.bis_interrupt_max_indemnity_period === null || isNaN(location.bis_interrupt_max_indemnity_period) || location.bis_interrupt_max_indemnity_period.length === 0)"
                >
                  <data-row large-label>
                    <template slot="name">Business Interruption Max Indemnity Period</template>
                    <template slot="value">{{ location.bis_interrupt_max_indemnity_period }}</template>
                  </data-row>
                </div>
                <div class="row row-xs" v-if="location.building_sum_insured > 0">
                  <data-row large-label>
                    <template slot="name">Building Sum Insured</template>
                    <template slot="value">{{ location.building_sum_insured | formatCurrency }}</template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">Building EML</template>
                    <template slot="value">{{ location.building_EML * 100 }}%</template>
                  </data-row>
                </div>
                <div class="row row-xs" v-if="location.machinery_sum_insured > 0">
                  <data-row large-label>
                    <template slot="name">Machinery Sum Insured</template>
                    <template slot="value">{{ location.machinery_sum_insured | formatCurrency }}</template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">Machinery EML</template>
                    <template slot="value">{{ location.machinery_EML * 100 }}%</template>
                  </data-row>
                </div>
                <div class="row row-xs" v-if="location.unspec_property_sum_insured > 0">
                  <data-row large-label>
                    <template slot="name">Unspecified Property Sum Insured</template>
                    <template slot="value">{{ location.unspec_property_sum_insured | formatCurrency }}</template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">Unspecified Property EML</template>
                    <template slot="value">{{ location.unspec_property_EML * 100 }}%</template>
                  </data-row>
                </div>
                <div class="row row-xs mt-2">
                  <data-row large-label>
                    <template slot="name">Total Insured</template>
                    <template slot="value">{{ location.total_location_sum_insured | formatCurrency }} {{ location.currency.currency_code }}</template>
                  </data-row>
                  <data-row large-label>
                    <template slot="name">Total EML</template>
                    <template slot="value">{{ location.total_location_EML * 100 }}%</template>
                  </data-row>
                </div>
              </card>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="row row-xs mt-2">
      <div class="col">
        <card>
          <div id="map-canvas" class="map-canvas tall-map"></div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import DataRow from "@common/src/components/DataRow";
export default {
  name: "Location",
  components: {
    DataRow
  },
  props: {
    programmeId: {
      type: [Number, String],
      required: true
    },
    localPolicyId: {
      type: [Number, String],
      required: true
    },
    locationId: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    drawLocationMap() {
      if (this.location === undefined) {
        return;
      }

      const google = window.google;

      const latLong = {lat: parseFloat(this.location.geo_latitude), lng: parseFloat(this.location.geo_longitude)};
      let map = new google.maps.Map(document.getElementById('map-canvas'), {
        center: latLong,
        zoom: 15,
      });

      new google.maps.Marker({
        position: latLong,
        map
      })
    },
    resizeMap() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(
          () => {
            this.drawLocationMap();
          },
          500
      );
    }
  },
  computed: {
    programme() {
      return this.$store.getters.programmes.find(programme => programme.id==this.programmeId);
    },
    localPolicy() {
      return this.$store.getters.localPoliciesForProgramme.find(localPolicy => localPolicy.local_policy_id==this.localPolicyId);
    },
    location() {
      if (this.localPolicy === undefined) {
        return undefined;
      }

      return this.localPolicy.locations.find(location => location.location_transaction_detail_id == this.locationId)
    },
    loadedLocation() {
      return this.location !== undefined;
    }
  },
  created() {
    window.addEventListener("resize", this.resizeMap);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeMap);
  },
  mounted() {
    this.drawLocationMap();
  }
}
</script>
<style scoped>
.tall-map {
  height: 600px;
}

.short-map {
  height: 300px;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

#map-canvas > div > div > div {
  overflow: hidden;
  border-radius: 6px;
}
</style>
