import { render, staticRenderFns } from "./Programmes.vue?vue&type=template&id=07adb97c&scoped=true&"
import script from "./Programmes.vue?vue&type=script&lang=js&"
export * from "./Programmes.vue?vue&type=script&lang=js&"
import style0 from "./Programmes.vue?vue&type=style&index=0&id=07adb97c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07adb97c",
  null
  
)

export default component.exports